import { getRoute } from "~/lib/routes";

export default defineNuxtRouteMiddleware(async (to) => {
	const contextToken = useCookie("sw-context-token");
	const { userFromContext, isReady } = process.server
		? useShopwareSessionImplementation(contextToken.value as string)
		: useShopwareSession();

	if (!isReady.value) {
		const timeout = setTimeout(() => {
			useBugsnag().notify("Shopware session is not ready after 7 seconds.");
		}, 7000);
		await until(isReady).toBeTruthy();
		clearTimeout(timeout);
	}

	const isLoggedIn = computed(() => {
		return !!userFromContext.value?.id;
	});

	if (!isLoggedIn.value) {
		if (to.fullPath.includes("/bestellen")) {
			return navigateTo(getRoute("checkoutLogin"));
		}

		return navigateTo(getRoute("accountLogin"));
	}
});
